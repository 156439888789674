<div :class="{price: true, competitor: item.competitor!=null}">
    <template v-if="item.price">
        {{formattedPrice(item.price.local_price, item.price.local_currency)}}
    </template>
    <div class="marker-container" v-if="item.competitor===null">
        <div class="marker gray">
            <span class="title">{{priceTool.priceTypeDetails[item.payment_type].title}}</span>
        </div>
    </div>
</div>
