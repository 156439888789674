<div>
    <div class="remove-from-compare-container">
        <div class="remove-from-compare" @click="() => {handlers.deleteFromCompare(column.data.id, !!column.data.competitor)}">X</div>
    </div>
    <a
        :href="column.data.link"
        target="_blank"
        class="img"
        v-if="src"
        :style="'background: url(' + src + ') 100% 100% no-repeat;'"
    />
</div>
