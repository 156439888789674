<template src="./questionnaire-competitors.html"></template>
<script>

import Modal from '@c/Modal';
import FormCheckbox from '@f/Check';
import './questionnaire-competitors.scss';

export default {
    name: 'CompetitorPopup',
    components: {
        Modal,
        FormCheckbox,
    },
    props: {
        competitorProducts: {
            type: Array,
            default() {
                return [];
            }
        },
        selectedCompetitorProducts: {
            type: Array,
            default() {
                return [];
            }
        },
        fnFormatPrice: {
            type: Function,
            default() {
                return () => {};
            }
        },
    },
    emits: ['update:displayCompetitorPopup', 'update:competitorsToCompare'],
    data() {
        return {
            selected: [...this.selectedCompetitorProducts],
        };
    },
    methods: {
        updateDisplayCompetitorPopup(display) {
            this.$emit('update:displayCompetitorPopup', display);
        },
        addToCompare() {
            this.$emit('update:competitorsToCompare', this.selected);
            this.updateDisplayCompetitorPopup(false);
        },
    },
};
</script>
