<teleport to="body">
    <Modal
        @on-ok="updateDisplayCompetitorPopup(false)"
        @on-close="updateDisplayCompetitorPopup(false)"
        :buttons="[]"
        class="competitor-products-popup"
    >
        <template v-slot:header>
            <h2 class="d-inline-block mr-1">Конкуренты</h2>
        </template>
        <template v-slot:body>
            <div class="competitor-products-container">
                <table>
                    <tr v-for="competitorProduct in competitorProducts">
                        <td>
                            <form-checkbox @click="" v-model="selected" :value="competitorProduct.id" />
                        </td>
                        <td>
                            <div class="img" style="background: url(/img/preview/models/thumbs40/180.png) 100% 100% no-repeat;"></div>
                        </td>
                        <td>
                            <div class="competitor-product-details">
                                <div class="product-name">{{competitorProduct.name}}</div>
                                <div class="competitor-name">{{competitorProduct.competitor.name}}</div>
                            </div>
                        </td>
                        <td>
                            <div class="competitor-product-price" v-if="competitorProduct.price">
                                {{fnFormatPrice(competitorProduct.price.local_price, competitorProduct.price.local_currency)}}
                            </div>
                        </td>
                    </tr>
                </table>
                <div @click="addToCompare" :class="{'competitor-add-to-compare-button': true, 'disabled': selected.length === 0}">
                    Добавить в сравнение
                </div>
            </div>
        </template>
    </Modal>
</teleport>
