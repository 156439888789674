<template src="./stock-availability.html"></template>
<script>

import './stock-availability.scss';

export default {
    name: 'ColumnStockAvailability',
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        item() {
            return this.column.data;
        }
    }
};

</script>
