<template src="./column-image.html"></template>
<script>

export default {
    name: 'ColumnImage',
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
        handlers: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        src() {
            return this.column.name;
        }
    },
};

</script>
