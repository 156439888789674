<div class="stock-availability">

    <template v-if="item.competitor">
        <div class="competitor-container">
            <div class="title">КОНКУРЕНТ</div>
            <div class="name">
                {{item.competitor.name}}
            </div>
        </div>
    </template>
    <template v-else>

        <div class="count"  v-if="item.full_count > 0">
            <div class="available text-green">В наличии</div>
            <div class="counter">{{item.count}} / {{item.full_count}} шт.</div>
        </div>
        <div class="fastest-delivery" v-else>Ближайшая поставка: {{item.fastestDelivery}}</div>
    </template>
</div>
