<template src="./column-price.html"></template>
<script>
import './price.scss';

import currencyFilter from '@/filters/currency';
import Price from '@/entities/price';

export default {
    name: 'ColumnPrice',
    props: {
        column: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        item() {
            return this.column.data;
        },
        priceTool() {
            return new Price();
        },
    },
    methods: {
        formattedPrice(price, currency = 'RUB') {
            return currencyFilter(price, currency);
        },
    }
};

</script>
